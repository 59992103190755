'use client';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ScriptProps } from 'next/script';
import { useEffect } from 'react';

import getLocaleProps from '@/lib/i18n';
import { useCexPrice } from '@/hooks/api';
import { useInitNetwork } from '@/hooks/app';

import Btns from '@/components/KLine/btns';
import Head from '@/components/KLine/Head';
import ShowPanel from '@/components/KLine/ShowPanel';
import Motion from '@/components/Motion';

import { TRADE_TOKENS } from '@/consts';

import { TradeTokenType } from '@/types';

const InfoPanel = dynamic(() => import('@/components/KLine/Info'), { ssr: false });
const KChart = dynamic(() => import('@/components/KLine/kChart'), { ssr: false });

const tokens = Object.values(TRADE_TOKENS).flatMap((item) => [`${item}_USDT`, `${item}_USD`]);

const KLinePage = () => {
  useInitNetwork();
  useCexPrice();

  const { query, replace, locale, asPath } = useRouter();

  useEffect(() => {
    const name = query.name as string;
    if (name) {
      if (tokens.includes(name)) {
        let symbol = Object.values(TRADE_TOKENS)[0];
        let type = TradeTokenType.U;
        if (name.toUpperCase().includes('_USDT')) {
          symbol = name.toUpperCase().replace('_USDT', '').toUpperCase();
        } else if (name.toUpperCase().includes('_USD')) {
          symbol = name.toUpperCase().replace('_USD', '').toUpperCase();
          type = TradeTokenType.Token;
        }

        if (!Object.values(TRADE_TOKENS).includes(symbol)) {
          symbol = Object.values(TRADE_TOKENS)[0];
        }

        const pathName = `${symbol}_${type === TradeTokenType.U ? 'USDT' : 'USD'}`;
        const pathArr = asPath.split('/kline/');
        if (pathArr.length == 2 && pathArr[1] !== pathName) {
          replace(`/${locale}/kline/${pathName}`);
        }
      } else {
        replace(`/${locale}/kline/${tokens[0]}`);
      }
    }
  }, [query.name]);

  return (
    <Motion full={true}>
      {typeof window !== 'undefined' && window.TECO_postMessage ? null : <Head />}
      <ShowPanel />
      <KChart />
      <InfoPanel />
      <Btns />
    </Motion>
  );
};
export const getStaticProps: GetStaticProps<ScriptProps> = getLocaleProps([
  'common',
  'lp',
  'trade',
  'kline',
  'questions',
]);

export async function getStaticPaths({ locales }: { locales: string[] }) {
  const paths = locales.flatMap((locale) => tokens.map((name) => ({ params: { name }, locale })));
  return {
    paths: paths,
    fallback: true,
  };
}

export default KLinePage;
