import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { appBackEvent } from '@/lib/utils';

import ChangeToken from '@/components/Modals/Trade/ChangeToken';

const Head = () => {
  const router = useRouter();
  const [changeTokenIsOpen, setChangeTokenIsOpen] = useState(false);

  const title = router.query?.name?.toString().replace('_', '').toUpperCase();

  const backAction = () => {
    router.back();
    appBackEvent('klineBackEvent');
  };

  return (
    <>
      <div className="flex w-full items-center justify-center h-11 mt-3">
        <div className="absolute left-5" onClick={backAction}>
          <svg className="w-5 h-5">
            <use href="#arrow-left"></use>
          </svg>
        </div>
        <div className="flex items-center" onClick={() => setChangeTokenIsOpen(true)}>
          <svg className="w-4 h-4 text-dark-dark">
            <use href="#menu"></use>
          </svg>
          <div className="ml-2 text-lg font-medium text-dark-dark">{title}</div>
        </div>
      </div>
      <ChangeToken isOpen={changeTokenIsOpen} onClose={() => setChangeTokenIsOpen(false)} />
    </>
  );
};
export default Head;
