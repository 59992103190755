import { Button } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { getStorage, setStorage } from '@/lib/storage';

import TestModal from '@/components/TestQuestions/TestQuestionsModal';

import { useTradeStore } from '@/stores';

import { TradeType } from '@/types';

const Btns = () => {
  const { t } = useTranslation(['kline']);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { push, locale, query } = useRouter();
  const { setTradeType } = useTradeStore();

  const handleTrade = (type: TradeType) => {
    if (getStorage('isSuccessTest')) {
      if (window.TECO_postMessage) {
        const symbolStr = query.name?.toString().toUpperCase() || 'BTCUSDT';
        window.TECO_postMessage({
          type: 'transActionEvent',
          name: '',
          payload: {
            symbol: symbolStr,
            api: type === TradeType.Long ? 'Buy' : 'Sell',
          }, //appType = "Buy" 或者"Sell"
        });
      }
      setTradeType(type);
      push(`/${locale}/trade/${query.name}`);
    } else {
      setIsOpenModal(true);
      setStorage('route', query.name);
    }
  };

  return (
    <div className="fixed w-full bg-white h-[120px] bottom-0 px-[25px] z-50 pt-5 shadow-inner">
      <div className="flex">
        <Button
          color="success"
          disableRipple
          disableAnimation
          radius="sm"
          className="w-full h-12 rounded text-white"
          onPress={() => handleTrade(TradeType.Long)}
        >
          {t('buy')}
        </Button>
        <Button
          color="danger"
          disableRipple
          disableAnimation
          radius="sm"
          className="w-full h-12 rounded ml-5"
          onPress={() => handleTrade(TradeType.Short)}
        >
          {t('sell')}
        </Button>
      </div>
      <TestModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      />
    </div>
  );
};
export default Btns;
