import { motion } from 'framer-motion';
import * as React from 'react';

export default function Motion({ children, full }: { children: React.ReactNode; full: boolean }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={full ? 'h-full' : ''}
    >
      {children}
    </motion.div>
  );
}
