import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { cn } from '@/lib/utils';
import { useDealCenterInfo } from '@/hooks/api';

import { DECIMAL_SCALE } from '@/consts';

const ShowPanel = () => {
  const { query } = useRouter();
  const symbol = query?.name?.toString().replace('_', '').padEnd(7, 'T');
  const symbolToken = query?.name
    ?.toString()
    .slice(query?.name?.indexOf('_') + 1, query?.name?.length);
  const symbolTokenBefore = query?.name?.toString().slice(0, query?.name?.indexOf('_'));
  const { oneDay } = useDealCenterInfo(symbol as string);
  const { t } = useTranslation(['kline']);
  const decimalScale = useMemo(() => {
    return symbolToken === 'USD' ? DECIMAL_SCALE[0] : DECIMAL_SCALE[1];
  }, [symbolToken]);

  return (
    <div className="flex w-full justify-between px-5 py-5">
      <div className="mr-2">
        <div className="text-2xl font-medium text-dark-dark">
          {parseInt(oneDay?.lastPrice || 0).toFixed(decimalScale)}
        </div>
        <div className="flex text-xs">
          <div className="text-dark-gray font-normal">
            ≈${parseFloat(oneDay?.lastPrice || 0).toFixed(decimalScale)}
          </div>
          <div
            className={cn(
              'text-green font-medium',
              oneDay?.priceChangePercent > 0 ? 'text-green' : 'text-danger',
            )}
          >
            {oneDay?.priceChangePercent > 0 ? '+' : ''}
            {oneDay?.priceChangePercent}%
          </div>
        </div>
      </div>
      <div className="flex text-[10px]">
        <div className="mr-5">
          <div className="text-dark-gray">{t('highest-in-24-hours')}</div>
          <div className="text-dark-dark">
            {parseInt(oneDay?.highPrice || 0).toFixed(decimalScale)}
          </div>
          <div className="text-dark-gray mt-2">{t('lowest-in-24-hours')}</div>
          <div className="text-dark-dark">
            {parseInt(oneDay?.lowPrice || 0).toFixed(decimalScale)}
          </div>
        </div>
        <div>
          <div className="text-dark-gray">{t('TT-volume', { token: symbolTokenBefore })}</div>
          <div className="text-dark-dark ">
            {parseInt(oneDay?.volume || 0).toFixed(decimalScale)}
          </div>
          <div className="text-dark-gray mt-2">{t('USDT-volume', { token: symbolToken })}</div>
          <div className="text-dark-dark">
            {parseInt(oneDay?.quoteVolume || 0).toFixed(decimalScale)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowPanel;
