import { Listbox, ListboxItem, Modal, ModalContent, ModalHeader } from '@nextui-org/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { rubik } from '@/lib/font';
import { cn } from '@/lib/utils';

import { usePricesStore } from '@/stores';

import { DECIMAL_SCALE, TRADE_TOKENS } from '@/consts';

import { TradeTokenKey, TradeTokenType } from '@/types';
import { ModalProps } from '@/types/props';

const Index = (props: ModalProps) => {
  const { t } = useTranslation(['common', 'trade']);

  const { cexPrices, prices } = usePricesStore();

  const { replace, locale, asPath } = useRouter();
  const path = `/${asPath.split('/')[1]}/`;
  const list = useMemo(() => {
    const list_usdt = Object.keys(TRADE_TOKENS).map((item) => {
      const key = item as TradeTokenKey;
      const cexPrice = cexPrices?.[key];
      return {
        symbol: TRADE_TOKENS[key],
        name: `${TRADE_TOKENS[key]}USDT`,
        price: parseInt(cexPrice?.lastPrice ?? '0').toFixed(DECIMAL_SCALE[0]),
        type: t('common:u-standard'),
        rate: cexPrice?.priceChangePercent ?? '0',
      };
    });
    const list_usd = Object.keys(TRADE_TOKENS).map((item) => {
      const key = item as TradeTokenKey;
      const cexPrice = cexPrices?.[key];
      return {
        symbol: TRADE_TOKENS[key],
        name: `${TRADE_TOKENS[key]}USD`,
        price: parseInt(cexPrice?.lastPrice ?? '0').toFixed(DECIMAL_SCALE[0]),
        type: t('common:token-standard'),
        rate: cexPrice?.priceChangePercent ?? '0',
      };
    });

    return [...list_usdt, ...list_usd];
  }, [cexPrices, t]);

  const onAction = (key: React.Key) => {
    let symbol = Object.values(TRADE_TOKENS)[0];
    let type = TradeTokenType.U;
    if (key.toString().includes('USDT')) {
      symbol = key.toString().replace('USDT', '');
    } else {
      symbol = key.toString().replace('USD', '');
      type = TradeTokenType.Token;
    }
    const pathName = `${symbol}_${type === TradeTokenType.U ? 'USDT' : 'USD'}`;
    const pathArr = asPath.split(path);
    if (pathArr.length == 2 && pathArr[1] !== pathName) {
      replace(`/${locale}${path}${pathName}`);
    }
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      placement="bottom"
      onClose={props.onClose}
      closeButton={
        <svg>
          <use href="#close"></use>
        </svg>
      }
      classNames={{
        base: 'm-0 rounded-b-none',
        closeButton: 'hover:bg-white top-4 right-2 w-7 h-7',
        backdrop: 'bg-black bg-opacity-40',
      }}
    >
      <ModalContent className="flex flex-col px-0 bg-dark-0">
        <ModalHeader className="flex flex-row justify-start p-0 px-5 pt-4 text-lg text-white font-medium">
          {t('trade:change-coin')}
        </ModalHeader>

        <Listbox
          selectionMode="single"
          classNames={{
            base: 'p-0',
          }}
          aria-label="Select token"
          onAction={onAction}
        >
          {list.map((item) => {
            return (
              <ListboxItem
                className="rounded-none border-b-1 border-dark-400 border-opacity-70"
                key={item.name}
                textValue={item.name}
                classNames={{
                  base: 'p-0 px-5 m-0 flex flex-row justify-center items-center data-[selectable=true]:focus:bg-white',
                  title: 'p-0',
                  wrapper: 'h-full bg-primary',
                  description: 'h-full bg-primary',
                  shortcut: 'hidden',
                  selectedIcon: 'hidden',
                }}
              >
                <div className="my-3 flex justify-between items-center">
                  <div className="flex justify-between items-center">
                    <Image
                      className="rounded-full"
                      src={`/images/tokens/${item.symbol}.png`}
                      alt=""
                      width={24}
                      height={24}
                    />
                    <div className="flex flex-col justify-between ml-2">
                      <span
                        className={cn(
                          'text-white font-medium text-sm leading-[18px]',
                          rubik.className,
                        )}
                      >
                        {item.name}
                      </span>
                      <span className="text-dark-gray text-xs">{item.type}</span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between items-end">
                    <span
                      className={cn(
                        'text-white font-medium text-sm leading-[18px]',
                        rubik.className,
                      )}
                    >
                      {item.price}
                    </span>
                    <span
                      className={cn(
                        'text-xs leading-5 !font-normal',
                        rubik.className,
                        Number(item.rate) < 0 ? 'text-warning' : 'text-green',
                      )}
                    >
                      {Number(item.rate) < 0 ? item.rate.toString() : '+' + item.rate}%
                    </span>
                  </div>
                </div>
              </ListboxItem>
            );
          })}
        </Listbox>
      </ModalContent>
    </Modal>
  );
};
export default Index;
