import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TestModal = (props: Props) => {
  const { t } = useTranslation(['questions']);
  const router = useRouter();
  const handleRouterJump = () => {
    props.onClose();
    setTimeout(() => {
      router.push('/test-questions/welcome');
    }, 500);
  };
  return (
    <div className="flex flex-col gap-2">
      <Modal
        key="test-questions"
        isOpen={props.isOpen}
        placement="bottom"
        classNames={{
          base: 'mx-0 my-0 rounded-b-none',
          header: 'py-3',
          backdrop: 'bg-black bg-opacity-40',
        }}
        onClose={props.onClose}
      >
        <ModalContent className="bg-black">
          <ModalHeader className="text-white font-medium text-base border-b border-dark-400 flex items-center justify-center">
            {t('contract-testing')}
          </ModalHeader>
          <ModalBody className="text-dark-dark text-sm px-4 py-0">
            <div className="flex justify-center mt-12 mb-7">
              <svg className="w-[68px] h-[68px]">
                <use href="#test"></use>
              </svg>
            </div>
            <p className="pb-2 text-dark">{t('learn-knowledge')}</p>
          </ModalBody>
          <ModalFooter className="flex justify-between gap-2 px-4 mb-4 ios-text-question-media">
            <Button
              className="w-full rounded text-sm font-medium text-white bg-dark-400"
              onPress={props.onClose}
              disableRipple
              disableAnimation
            >
              {t('learn-more')}
            </Button>
            <Button
              className="w-full rounded text-sm font-medium text-dark-500"
              color="primary"
              disableRipple
              disableAnimation
              onClick={() => handleRouterJump()}
            >
              {t('start-now')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
export default TestModal;
